<template>
    <div class="home">
      <div class="container">
        <Top :activeTitle="activeTitle" :list="list" />
        <div class="connect"  v-for="(item, index) in connectList" :key="index" :title="item.title">
          <div class="connect_item cleatFix">
            <div class="connect_left"><img :src="item.img" alt=""></div>
            <div class="connect_middle">
              <div class="user_name">บัญชี {{ item.user_name }}</div>
              <div class="information" ref="information" >{{ item.information }}</div>
              <!-- <input type="hidden" v-model="textToCopy" value="{{ item.information }}"> -->
            </div>
            <div class="connect_right">
              <div class="connect_botton"
                v-clipboard:copy="item.information"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                คัดลอกลิ้งก์
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterNav  :activeIndex="active"/>
      <FooterText />
    </div>
      
      
      
  </template>
  
  <script>
    import Vue from 'vue'
    import FooterNav from "../components/FooterNav.vue";
    import FooterText from "../components/FooterText.vue";
    import Top from "../components/Top.vue";
    import VueClipboard from 'vue-clipboard2'
    Vue.use(VueClipboard)
    
  export default {
    components: {
        FooterNav,
        FooterText,
        Top,
    },
    data() {  
      return {
        active: 3,
        activeTitle:1,
        connectList:[],
        textToCopy:'',
        list:[
          {name:'ปัญหาที่พบบ่อย',url:'/CommonProblem'},
          {name:'ติดต่อเจ้าหน้าที่',url:'/Connect'},
          {name:'นโยบายความเป็นส่วนตัว',url:'/Privacy'},
          {name:'ข้อตกลงการใช้บริการ',url:'/CyberSecurity'},
        ]
  
      };
    },
    computed: {

    },
    mounted() {
      
    },
    created() {    
      this.getConnectList()
    },
    methods:{

      getConnectList(){
          this.service.post('Home/getConnectList',{})
          .then((res) => {
              this.connectList = res.data.data
          }) 
      },
      onCopy() {
        this.$toast({
          position: 'top',
          message: 'คัดลอกสำเร็จ',//复制成功
        })
      },
      onError() {
        this.$toast({
          position: 'top',
          message: 'คัดลอกไม่สำเร็จ', //复制失败
        })
      },
    }
  };
  </script>
  
  <style scoped lang="less">
    .connect{
      margin-top: 40px;
    }
    .connect_item{
      width: 630px;
      height: 130px;
      border-radius: 20px;
      background-color: #FFFFFF;
      padding: 30px;
      margin-bottom: 30px;
      .connect_left{
        float: left;
        width: 130px;
        height: 130px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .connect_middle{
        float: left;
        width: 340px;
        margin-left: 15px;
        margin-top: 24px;
        .user_name{
          font-size: 28px;
          color: #333333;
          line-height: 40px;
        }
        .information{
          font-size: 28px;
          line-height: 40px;
          color: #999999;
        }
      }
      .connect_right{
        margin-top: 38px;
        float: left;
        width: 145px;
        height: 55px;
        line-height: 55px;
        background:linear-gradient(to right,#357BFF,#37DCF3);
        border-radius: 28px;
        color: #FFFFFF;
        font-size: 24px;
        text-align: center;
      }
    }
  </style>
  
  